import Img from "gatsby-image"
import GatsbyLink from "gatsby-link"
import React from "react"
import styled from "styled-components"
import { usePlaceholder } from "../../hooks/placeholder"
import { MAIN_COLOR } from "../../variables"

const Card = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 4.5454rem;
  }
`

const CardImage = styled.div`
  width: 135px;
  height: 135px;
  margin-bottom: 1.45rem;
  background-image: ${props => props.src ? 'url("' + props.src + '")' : ''};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media all and (min-width: 768px) {
    width: 150px;
    height: 150px;
  }
`

const StyledImg = styled(Img)`
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 1.45rem;
`

const CardTitle = styled.h3`
  font-size: 1.1818em;
  font-family: "Quando", serif;
  margin-bottom: 0.909rem;
`

const CardSubtitle = styled.h4`
  font-size: 1em;
  font-family: "Quando", serif;
  margin-bottom: 0.909rem;
  font-weight: 400;
`

const CardText = styled.div`
  margin: 0;
`

const Link = styled(GatsbyLink)`
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  color: #fff;
  box-shadow: 0px 2px 16px 0px #8393d7;
  background-color: ${MAIN_COLOR};
  padding: 18px 32px;
  max-width: 290px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #333b9c;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const RequiredOfferCards = ({ elements = [], showLinks = true }) => {
  const { placeholder } = usePlaceholder()

  return elements.map(({ logo, partner, name, quantity, description }, idx) => (
    <Card key={idx} className="wow fadeIn" data-wow-delay={`${idx * 0.1}s`}>
      {logo ? <CardImage src={logo} /> : <StyledImg fluid={placeholder} />}
      {/* <CardTitle>{partner.name}, {partner.city.name}</CardTitle> */}
      <CardTitle>{quantity} db {name}, {partner.city.name}</CardTitle>
      <CardText dangerouslySetInnerHTML={{ __html: description }}></CardText>
      {showLinks && <Link to="/felajanlas" state={{
        requiredOffers: elements
      }}>Segítek</Link>}
    </Card>
  ))
}

export default RequiredOfferCards
