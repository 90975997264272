import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import RequiredOfferCards from "../components/required-offers/RequiredOfferCards"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"
import { getRequiredOffers } from "../services/api"

const OfferingsPageContainer = styled.div`
  margin: 0 auto;
  padding: 4.5454em 0.681em 8.1818em 0.681em;
  max-width: 760px;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

const OfferingsPage = () => {
  const [requiredOffers, setRequiredOffers] = useState([])

  useEffect(() => {
    loadRequiredOffers();
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const wow = require("wowjs")
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadRequiredOffers = async () => {
    try {
      const result = await getRequiredOffers()
      setRequiredOffers(result.data, [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout>
      <SEO title="Felajánlás" />
      <OfferingsPageContainer>
        <SectionTitle>Felajánlási kérelmek</SectionTitle>
        <Grid>
          <RequiredOfferCards elements={requiredOffers} />
        </Grid>
      </OfferingsPageContainer>
    </Layout>
  )
}

export default OfferingsPage
